import React from 'react'
import heroImage from './hero-image.svg'

function Hero() {
  return (
    <div className="pt-10 bg-stone-800 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">A better way to</span>
                <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-sky-400 to-blue-600 sm:pb-5">track RSA</span>
              </h1>
              <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                Track student progress toward proficiency without the paperwork. Automate your Reading Sufficiency Act compliance.
              </p>
              <div className="mt-5 sm:mt-6">
                <form action="#" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                  <div className="sm:flex">
                    <div className="mt-3 sm:mt-0">
                      <a href="/contact" className="block w-full py-3 px-28 rounded-md shadow bg-gradient-to-r from-sky-500 to-blue-600 text-white font-medium hover:from-sky-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 focus:ring-offset-gray-900">Learn more</a>
                    </div>
                  </div>
                  <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                    Questions? Contact us at
                    {' '}
                    {' '}
                    <a href="mailto:support@okrsa.com">support@okrsa.com</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <img className="w-full ml-16 lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src={heroImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
