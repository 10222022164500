import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import Header from './header'
import Help from './help'
import Footer from './footer'

function Product() {
  const features = [
  { name: 'Monitor Schools', description: 'View RSA status and compliance across all schools'  },
  {
    name: 'Track Interventions',
    description: 'Define district level reading interventions and track the results when they get assigned',
  },
  {
    name: 'Import Assessments',
    description: 'Simple interface and clear directions for data imports',
  },
  { name: 'In District Transfer', description: 'All student data automatically transfers between schools if a student transfers' },
  { name: 'Out of District Transfer', description: 'Easy to share data with the next district if a student moves out of your district' },
  { name: 'RSA Status', description: 'Real time student RSA statuses across the whole district' },
  { name: 'Testing Window Report', description: 'Easily find the students who need APP/IPRIs or Exemption letters' },
  { name: 'Searchable Students', description: 'Quickly find students RSA status, APP/IPRI status, grade level and more.' }
]

  return (
    <>
    <Header />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">District Administrator</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Let us take that for you</p>
          <p className="mt-4 text-lg text-gray-500">
            OK RSA specifically designed to decrease the amount of time that you spend ensuring that you are complying with the state law in your classroom
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((f) => (
              <div key={f.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{f.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{f.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <Help />
    <Footer />
    </>
  )
}

export default Product
