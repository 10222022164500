import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './about'
import Contact from './contact'
import ProductTeacher from './product-teacher'
import ProductSchool from './product-school'
import ProductDistrict from './product-district'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="product-teacher" element={<ProductTeacher />} />
        <Route path="product-school" element={<ProductSchool />} />
        <Route path="product-district" element={<ProductDistrict />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
