import React from 'react'
import collegeBound from './images/districts/college-bound/logo.jpeg'
import tulsaLegacy from './images/districts/tulsa-legacy/logo.jpeg'

function TrustedBy() {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
          TRUSTED BY SCHOOL DISTRICTS ACROSS OKLAHOMA
        </p>
        <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4">
          <div className="col-span-3 flex justify-center md:col-span-2 lg:col-span-2">
            <img className="h-28" src={collegeBound} alt="College Bound" />
          </div>
          <div className="col-span-3 flex justify-center md:col-span-2 lg:col-span-2">
            <img className="h-28" src={tulsaLegacy} alt="Tulsa Legacy" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrustedBy
