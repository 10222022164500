import React from 'react'

function Footer() {
  return (
    <footer className="bg-stone-800">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div className="px-5 py-2">
            <a href="/contact" className="text-base text-stone-200 hover:text-gray-900">
              Contact
            </a>
          </div>
          {/* TODO:
          <div className="px-5 py-2">
            <a href="/about" className="text-base text-stone-200 hover:text-gray-900">
              About
            </a>
          </div>
           */}
        </nav>

        <p className="mt-8 text-center text-base text-stone-400">
          &copy; 2022 OK RSA. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
