import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import Header from './header'
import Help from './help'
import Footer from './footer'

function Product() {
  const features = [
  { name: 'Track APP Progress', description: 'School wide views allow you to track APP/IPRI progress' },
  { name: 'Benchmark Comparison', description: 'All assessment scores are marked as above or below the benchmark when they are imported into the system'  },
  {
    name: 'Assessment Imports',
    description: 'Student assessments are imported after each testing window',
  },
  {
    name: 'State Reporting',
    description: 'We have replicated the all of the state reports required for RSA compliance',
  },
  { name: 'Searchable Students', description: 'Quickly find students RSA status, APP/IPRI status, grade level and more.' },
  { name: 'Testing Window Report', description: 'Know exactly which students met exemptions and which student need an APP/IPRI' },
  { name: 'Data Transfer', description: 'All student data automatically transfers between schools meaning there is no need to transfer folders' },
  { name: 'Track Portfolio', description: 'Upload documents and attach standards to track student portfolio progress' }
]

  return (
    <>
    <Header />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">School Admins & Coordinators</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Let us take that for you</p>
          <p className="mt-4 text-lg text-gray-500">
            OK RSA helps you support your teachers as you ensure that you school site is compliant with the Reading Sufficiency Act
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((f) => (
              <div key={f.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{f.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{f.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <Help />
    <Footer />
    </>
  )
}

export default Product
