import React from 'react'
import Header from './header'
import logo from './long-logo.svg'

export default function About() {
  {/* TODO:
  const stats = [
    { label: 'Founded', value: '2021' },
    { label: 'Students', value: '855' },
    { label: 'Districts', value: '2' },
    { label: 'Screeners Analyzed', value: '3336' },
  ]
  */}

  return (
    <>
    <Header />
    <div className="relative bg-white py-16 sm:py-24">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div className="relative sm:py-16 lg:py-0">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
            <img
              className="h-12"
              src={logo}
              alt="OK RSA"
            />
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1594608661623-aa0bd3a69d98?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2748&q=80"
                alt=""
              />
              <div className="absolute inset-0 bg-blue-500 mix-blend-multiply" />
              <div className="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-90" />
              <div className="relative px-8">
                <div>
                  <img
                    className="h-12 rounded-md"
                    src={logo}
                    alt="OK RSA"
                  />
                </div>
                <blockquote className="mt-8">
                  <div className="relative text-lg font-medium text-white md:flex-grow">
                    <svg
                      className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-400"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative">
                      After learning about some of the challenges that teachers face with RSA, I knew that I could help.
                    </p>
                  </div>

                  <footer className="mt-4">
                    <p className="text-base font-semibold text-blue-200">Holden Mitchell, Founder of OK RSA</p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
              Eliminating the busy work of RSA
            </h2>
            <div className="mt-6 text-gray-500 space-y-6">
              <p className="text-lg">
                {/* TODO: Tell the story of how it started */}
                {/* TODO: Don't anger the state by saying the process is a burden */}
                OK RSA all started when I was working at Tulsa Public Schools in the IT department. Our district RSA coordinator came to me to ask if we could generate 2300 PDFs to document the 3rd grade Promotion and Retention decisions for principals to sign. We were able to make it work and I
              </p>
              <p className="text-base leading-7">
                From that experience, I started to learn about all of the other documents and processes that teachers, RSA Coordinators, and school administrators needed to follow in order to comply with the Reading Sufficiency Act.
              </p>
              <p className="text-base leading-7">
                In the 2021-2022 school year, OK RSA partnered with two local charter schools to build out the system. I believe that w
              </p>
            </div>
          </div>

          {/* TODO:
          <div className="mt-10">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
              {stats.map((stat) => (
                <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
           */}
        </div>
      </div>
    </div>
    </>
  )
}
