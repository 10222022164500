import React from 'react'
import './App.css'
import Features from './features'
import Header from './header'
import Footer from './footer'
import Hero from './hero'
import TrustedBy from './trusted-by'
import Help from './help'

function App() {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <Header />
        <main>
          <Hero />
          <Features />
          <Help />
          <TrustedBy />
          {/* TODO: Add Helpful Resources */}
        </main>
        <Footer />
      </div>
    </div>

  )
}

export default App
